<template>
  <component :is="`h${tag > 6 ? 6 : tag}`"
             class="title tw-600"
             :class="classList[tag]">
    <slot></slot>
  </component>
</template>
<script>
export default {
  props: {
    tag: {
      required: true,
      type: String
    }
  },
  data: () => ({
    classList: {
      1: 'ts-28 ts-sm-34 ts-lg-60',
      2: 'ts-28 ts-sm-34 ts-lg-60',
      3: 'ts-28 ts-sm-34 ts-lg-50',
      4: 'ts-26 ts-sm-34 ts-lg-40',
      5: 'ts-26 ts-sm-28 ts-sm-28',
      6: 'ts-24 ts-sm-26 ts-md-28'
    }
  })
}
</script>

<template>
  <div class="flipper">
    <div class="flipper__wrapper">
      <div class="flipper__column">
        <div class="flipper__sprite" :data-pos="crypto.curr">
          <img src="/img/components/flipper/img-sprite-currency.svg" alt="">
        </div>
        <p class="tc-waikawa ts-12 ts-md-16 tw-500 ta-center mb-0 mt-3">
          {{ $store.state.cryptoNamespace[crypto.values[crypto.curr - 1]] }}
        </p>
      </div>
      <div class="flipper__column">
        <div class="img img--flipper" :class="{'active': inProgress}">
          <img src="/img/icons/ui/img-flipper.svg" alt="flip">
        </div>
      </div>
      <div class="flipper__column">
        <div class="flipper__sprite" :data-pos="currency.curr">
          <img src="/img/components/flipper/img-sprite-currency.svg" alt="">
        </div>
        <p class="tc-waikawa ts-12 ts-md-16 tw-500 ta-center mb-0 mt-3">
          {{ $store.state.cryptoNamespace[crypto.values[currency.curr - 1]] }}
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    inProgress: false,
    crypto: {
      max: 12,
      curr: 2,
      values: ['czk', 'btc', 'eth', 'bch', 'ltc', 'xrp', 'matic', 'xlm', 'algo', 'trx', 'ada', 'bnb']
    },
    currency: {
      max: 12,
      curr: 1,
      values: ['czk', 'btc', 'eth', 'bch', 'ltc', 'xrp', 'matic', 'xlm', 'algo', 'trx', 'ada', 'bnb']
    }
  }),
  computed: {
    cc () {
      return this.$t('components.flipper')
    }
  },
  methods: {
    getRandomPos (max) {
      return Math.floor(Math.random() * (max - 1 + 1) + 1)
    },
    duplicateFilter (target, max) {
      let rand = null
      do { rand = this.getRandomPos(max) }
      while (target === rand)
      return rand
    }
  },
  mounted () {
    setInterval(() => {
      this.inProgress = true
      let cryptoCurr = this.duplicateFilter(this.crypto.curr, this.crypto.max)
      let currencyCurr = this.duplicateFilter(this.currency.curr, this.currency.max)
      while (cryptoCurr === currencyCurr) {
        cryptoCurr = this.duplicateFilter(this.crypto.curr, this.crypto.max)
        currencyCurr = this.duplicateFilter(this.currency.curr, this.currency.max)
      }
      this.crypto.curr = cryptoCurr
      this.currency.curr = currencyCurr
      setTimeout(() => {
        this.inProgress = false
      }, 1000)
    }, 2000)
  }
}
</script>

<template>
  <p class="text"
             :class="classList[tag]">
    <slot></slot>
  </p>
</template>
<script>
export default {
  props: {
    tag: {
      required: false,
      type: String,
      default: '0'
    }
  },
  data: () => ({
    classList: {
      0: '',
      1: 'ts-14 ts-md-16',
      2: 'ts-16 ts-md-16',
      3: 'ts-16 ts-md-20',
      4: 'ts-18 ts-md-24',
      5: 'ts-18 ts-md-30',
      6: 'ts-20 ts-md-20',
      7: 'ts-24 ts-md-26'
    }
  })
}
</script>

<template>
  <main class="main" data-view="exchange">
    <section class="section" id="s1">
      <div class="container">
        <div class="row">
          <div class="col-12 col-xl-10 offset-xl-1">
            <div class="px-xl-5">
              <ui-text tag="5" class="tc-waikawa tw-500 lh-35 ta-center mb-4">
                {{ cc.s1.pre }}
              </ui-text>
              <ui-title tag="2" class="ta-center mb-4">
                {{ cc.s1.title }}
              </ui-title>
              <p class="text tc-gray lh-35 ta-center ts-14 ts-md-16" v-html="cc.s1.text[0]"/>
            </div>
          </div>
        </div>
        <div class="row my-5 py-md-4">
          <div class="col-12">
            <comp-flipper />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <ui-text tag="2" class="tc-gray lh-35">
                {{ cc.s1.text[1] }}
              </ui-text>
          </div>
          <div class="col-12 col-lg-6">
            <ui-text tag="2" class="tc-gray lh-35">
                {{ cc.s1.text[2] }}
              </ui-text>
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="s2">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 col-xl-10 offset-xl-1">
            <ui-title tag="2" class="ta-center mb-4 mb-md-5">
              {{ cc.s2.title }}
            </ui-title>
            <ui-text tag="3" class="tc-gray lh-175 ta-center mb-md-4">
              {{ cc.s2.text }}
            </ui-text>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-6">
            <div class="img img--exchange img--exchange-s2-1">
              <img src="/img/pages/exchange/img-s2-1.png" alt="exhcnage" />
            </div>
          </div>
          <div class="col-12 col-lg-6">
            <div class="exchange mb-5 pb-lg-3">
              <div class="ico ico--exchange mb-md-4 me-3 me-md-4"
                   :class="`ico--exchange-${cc.s2.list[0].theme}`">
                <img :src="`/img/icons/ico-${cc.s2.list[0].key}-${cc.s2.list[0].theme}.svg`" :alt="cc.s2.list[0].key"/>
              </div>
              <div>
                <ui-text tag="3" class="mb-3 tw-600">
                  {{ cc.s2.list[0].title }}
                </ui-text>
                <ui-text tag="2" class="tc-gray lh-175 ta-center ta-md-start mb-4">
                  {{ cc.s2.list[0].text }}
                </ui-text>
                <ui-link :data="cc.s2.list[0].link" :modifier="cc.s2.list[0].link.modifier" :color="cc.s2.list[0].link.theme" class="ts-16 ts-md-20" />
              </div>
            </div>
            <div class="exchange">
              <div class="ico ico--exchange mb-md-4 me-3 me-md-4"
                   :class="`ico--exchange-${cc.s2.list[1].theme}`">
                <img :src="`/img/icons/ico-${cc.s2.list[1].key}-${cc.s2.list[1].theme}.svg`" :alt="cc.s2.list[1].key"/>
              </div>
              <div>
                <ui-text tag="3" class="mb-3 tw-600">
                  {{ cc.s2.list[1].title }}
                </ui-text>
                <ui-text tag="2" class="tc-gray lh-175 ta-center ta-md-start d-inline">
                  {{ cc.s2.list[1].text[0] }}
                </ui-text>
                <ui-text tag="2" class="lh-175 ta-center ta-md-start d-inline" style="text-decoration: underline">
                  {{ cc.s2.list[1].text[1] }}
                </ui-text>
                <ui-text tag="2" class="tc-gray lh-175 ta-center ta-md-start d-inline">
                  {{ cc.s2.list[1].text[2] }}
                </ui-text>
                <div class="mb-4">
                </div>
                <ui-link :data="cc.s2.list[1].link" :modifier="cc.s2.list[1].link.modifier" :color="cc.s2.list[1].link.theme" class="ts-16 ts-md-20" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import compFlipper from '@/components/Flipper'
import uiTitle from '@/components/UI/Title'
import uiText from '@/components/UI/Text'
import uiLink from '@/components/UI/Link'
export default {
  components: {
    compFlipper, uiTitle, uiText, uiLink
  },
  computed: {
    cc () {
      return this.$t('pages.exchange')
    }
  },
  metaInfo () {
    const meta = this.$t('meta.exchange')
    return {
      titleTemplate: '%s' + (meta.title ? ' | ' + meta.title : ''),
      meta: [
        { name: 'keywords', content: meta.keywords },
        { name: 'description', content: meta.description },
        { property: 'og:description', content: meta.description }
      ]
    }
  },
  mounted () {
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 100)
  }
}
</script>
